import React from "react"
import Footer from "../components/footer/footer"
import NavigationBar from "../components/navbar/navigationBar"
import Roadmap from "../components/roadmap/Roadmap"
import "./roadmap.less"

function roadmap() {
  return (
    <div className="roadmap-page-container">
      <div className="nav-bg">
        <NavigationBar />
      </div>
      <Roadmap />
      <Footer />
    </div>
  )
}

export default roadmap
