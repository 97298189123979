import React from "react"
import { Container, Row, Col ,Image} from "react-bootstrap"
import RoadPoint from "../../images/Our-Road-point.svg"
import Email from "../aboutus/email/Email"
import "./roadmap.less"

const Roadmap = props => {
  return (
      <Container className="roadmap-container" fluid>
        <div className="roadmap">
          <div className="top-bg-image"></div>
          <div className="ellipse-orange"></div>
          <div className="ellipse-blue"></div>
          <Row>
            <Col sm={12}>
              <h4 className="h1 text-center">Our Roadmap</h4>
            </Col>
          </Row>
          <Row className="roadmap-row">
            <Col sm="6" className="item-left">
            <Image src={RoadPoint} className="item-left-point"></Image>
              <span className="phase">PHASE 1</span>
              <p className="display-2 mt-3">2016</p>
              <ul>
                <li className="bold-li">General Blockchain analysis</li>
                <li className="bold-li">Identify fatal flaws of existing solutions</li>
                <li>Scalability</li>
                <li>Sustainable security</li>
              </ul>
            </Col>
            <Col sm="6"></Col>
          </Row>
          <Row className="roadmap-row">
            <Col sm="6"></Col>
            <Col sm="6" className="item-right">
            <Image src={RoadPoint} className="item-right-point"></Image>
              <span className="phase">PHASE 2</span>
              <p className="display-2 mt-3">2017</p>
              <ul>
                <li className="bold-li">Begin collaboration with professional cryptographer </li>
                <li>Proof of Stake solutions</li>
                <li>BLS signatures</li>
                <li className="bold-li">Form development team</li>
                <li>Back-end Engineer</li>
                <li>C++ programming experts</li>
                <li className="bold-li">Begin development</li>
                <li>Fİrst Github commit Nov 1,2017</li>
              </ul>
            </Col>
          </Row>
          <Row className="roadmap-row">
            <Col sm="6" className="item-left">
            <Image src={RoadPoint} className="item-left-point"></Image>
              <span className="phase">PHASE 3</span>
              <p className="display-2 mt-3">2018-2019</p>
              <ul>
                <li className="bold-li">Ongoing Development</li>
                <li>Import BLS12-381 curve functions</li>
                <li>First primitive miner</li>
                <li>Address critical security issues</li>
                <li>Coin age protection</li>
                <li>Improve address generation code</li>
              </ul>
            </Col>
            <Col sm="6"></Col>
          </Row>
          <Row className="roadmap-row">
            <Col sm="6"></Col>
            <Col sm="6" className="item-right">
            <Image src={RoadPoint} className="item-right-point"></Image>
              <span className="phase">PHASE 4</span>
              <p className="display-2 mt-3">2018-2019</p>
              <ul>
                <li className="bold-li">Mainnet Launch</li>
                <li>March 13,2019</li>
                <li className="bold-li">Ecosystem Development</li>
                <li>Website</li>
                <li>Block Explorer</li>
                <li>Exchange listing</li>
                <li>Wallet design and documentation</li>
              </ul>
            </Col>
          </Row>
          <Row className="roadmap-row">
              <Col sm="6" className="item-left">
              <Image src={RoadPoint} className="item-left-point"></Image>
                  <span className="phase">PHASE 5</span>
                  <p className="display-2 mt-3">2021</p>
                  <ul>
                      <li className="bold-li">Petrachor Wallet</li>
                      <li>Progressive web application</li>
                      <li>Cross-platform compatibility</li>
                      <li>Optimized user experience</li>
                      <li className="bold-li">Ecosystem Development</li>
                      <li>Community building</li>
                      <li>Exchange listing applications</li>
                      <li>General documentation</li>
                      <li>Website expansion and SEO</li>
                      <li className="bold-li">Resarch</li>
                      <li>Scaling solutions</li>
                      <li>Decentralized applications</li>
                  </ul>
              </Col>
          </Row>
          <div className="bottom-bg-image"></div>
        <Email/>
        </div>
      </Container>
  )
}

export default Roadmap
